import React from "react";
import { Event } from "../../../utils/tracking";
import { Link as A, H4, Paragraph } from "@wel-shy/portfolio-component-library";
import { ProjectWrapper } from "./styles";

interface ProjectProps {
  description: string;
  link: string;
  shortName: string;
  title: string;
  implementation: {
    repoName: string;
    link: string;
    description: string;
  }[];
}

export const Project = ({
  description,
  implementation,
  link,
  shortName,
  title,
}: ProjectProps) => {
  const imp = implementation ? (
    <div>
      {implementation.map((imp) => (
        <div key={imp.repoName}>
          <A
            href={imp.link}
            target="_blank"
            onClick={() => Event("Repositories", "click", imp.repoName)}
          >
            {imp.description}
          </A>
          <br />
        </div>
      ))}
    </div>
  ) : (
    <></>
  );

  return (
    <ProjectWrapper>
      <A href={link} onClick={() => Event("Project", "click", shortName)}>
        <H4>{title}</H4>
      </A>
      <div>
        <Paragraph>{description}</Paragraph>
        {imp}
      </div>
    </ProjectWrapper>
  );
};
