import React from "react";
import { Container, H2, Tag } from "@wel-shy/portfolio-component-library";
import { useStaticQuery, graphql } from "gatsby";
import { SkillTag, TitleWrapper, HeaderTagGroup } from "./styles";
import { TrelloGqlResult } from "../../../models";

interface TrelloNode {
  id: string;
  name: string;
  listName: string;
}

export const Skills = () => {
  let {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { regex: "/Skills/" } }
        sort: { fields: [index], order: ASC }
      ) {
        edges {
          node {
            id
            name
            listName: list_name
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  if (edges.length <= 0) return null;

  const nodes = edges.map((edge) => edge.node);
  const technicalSkills = nodes.filter(
    ({ listName }) => listName === "Skills[section]"
  );
  const secondarySkills = nodes.filter(
    ({ listName }) => listName === "Secondary Skills"
  );

  const skills = [
    ...technicalSkills.map((skill) => (
      <SkillTag key={skill.id} color="primary">
        {skill.name}
      </SkillTag>
    )),
    ...secondarySkills.map((skill) => (
      <SkillTag key={skill.id} color="info">
        {skill.name}
      </SkillTag>
    )),
  ];

  return (
    <Container id="skills">
      <TitleWrapper>
        <H2>Skillset</H2>
        <HeaderTagGroup>
          <Tag color="primary">Technical</Tag>
          <Tag color="info">Secondary</Tag>
        </HeaderTagGroup>
      </TitleWrapper>

      <div>{skills}</div>
    </Container>
  );
};
