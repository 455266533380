import styled from "styled-components";

export const Wrapper = styled.div`
  h3,
  h4 {
    margin-bottom: 0.25em;
  }

  p {
    margin-bottom: 3em;
  }
`;
