import React from "react";
import {
  Container,
  Paragraph,
  Link,
} from "@wel-shy/portfolio-component-library";
import { Wrapper } from "./styles";
import { Event } from "../../utils/tracking";

interface ThanksProps {
  email?: string;
}

export const Thanks = ({ email }: ThanksProps) => (
  <Wrapper as={Container} id="thanks">
    <Paragraph>
      Thanks for stopping by!
      {email && (
        <>
          {" "}
          <Link
            onClick={() => Event("Email", "click", "Opened Link")}
            href={email}
          >
            Email
          </Link>{" "}
          me if you like what you see.
        </>
      )}
    </Paragraph>
  </Wrapper>
);
