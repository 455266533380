import styled from "styled-components";
import { ITheme, Link, Paragraph } from "@wel-shy/portfolio-component-library";

export const HeaderLink = styled(Link)`
  color: ${({ theme }) => (theme as ITheme).colors.textAccent};

  &:hover {
    color: ${({ theme }) => (theme as ITheme).colors.text};
  }
`;

export const ContentParagraph = styled(Paragraph)`
  a {
    color: ${({ theme }) => (theme as ITheme).colors.primary};
    text-decoration: none;
    transition: 0.25s ease all;

    &:hover {
      color: ${({ theme }) => (theme as ITheme).colors.textAccent};
    }
  }
`;
