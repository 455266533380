import React from "react";
import { Container, H2 } from "@wel-shy/portfolio-component-library";
import { useStaticQuery, graphql } from "gatsby";
import { Project } from "./Project";
import { TrelloGqlResult } from "../../models/TrelloGqlResult";

interface TrelloNode {
  id: string;
  name: string;
  listName: string;
  content: string;
}

export const Projects = () => {
  let {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { eq: "Projects[section]" } }
        sort: { fields: [index], order: ASC }
      ) {
        edges {
          node {
            id
            name
            content
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  if (edges.length <= 0) return null;

  const projects = edges.map(({ node: { content, ...rest } }) => ({
    ...rest,
    content: JSON.parse(
      decodeURI(content).replace(/`/g, "").replace(/json/g, "")
    ),
  }));

  return (
    <Container id="projects">
      <H2>
        Projects <span role="img" aria-label="hammer emoji" />
        🔨
      </H2>
      {projects.map((project) => (
        <Project
          description={project.content.description}
          implementation={project.content.implementation}
          key={project.id}
          link={project.content.link}
          title={project.content.title}
          shortName={project.content.shortName}
        />
      ))}
    </Container>
  );
};
