import { ITheme } from "@wel-shy/portfolio-component-library";
import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;
  margin-bottom: 40px;

  @media (max-width: ${({ theme }) => (theme as ITheme).sizes.screen.l}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => (theme as ITheme).sizes.screen.s}px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  transition: 0.25s ease all;

  :hover {
    box-shadow: 0 1px 10px 0 ${({ theme }) => (theme as ITheme).colors.grey};
  }
`;
