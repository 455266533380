import React from "react";
import { graphql, StaticQuery } from "gatsby";

import { PageWrapper } from "../components/PageWrapper";
import { Analytics } from "../components/Analytics";
import { Intro } from "../components/Intro";
import { Skills } from "../components/Skills";
import { RecentWork } from "../components/RecentWork";
import { Projects } from "../components/Projects";
import { Thanks } from "../components/Thanks";
import { Content } from "../components/Content";

interface TrelloNode {
  id: string;
  name: string;
  listName: string;
  content: string;
}

const App = ({
  sections: { edges },
}: {
  sections: { edges: { node: TrelloNode }[] };
}) => {
  const generateSectionByType = (t: string) =>
    edges
      .map((edge) => edge.node)
      .filter(({ name }) => name === `[type:${t}]`)
      .map(({ listName }) => <Content key={listName} listName={listName} />);

  const { content: email } = edges
    .map((edge) => edge.node)
    .find((node) => node.name === "Email") || { content: undefined };

  return (
    <PageWrapper pageTitle="Daniel Welsh">
      <Analytics />
      <Intro />
      <Skills />
      <RecentWork />
      <Projects />
      {generateSectionByType("content")}
      {generateSectionByType("list")}
      <Thanks email={email} />
    </PageWrapper>
  );
};

const Application = () => (
  <StaticQuery
    query={graphql`
      {
        allTrelloCard(
          filter: { list_name: { regex: "/[section]/" } }
          sort: { fields: id, order: ASC }
        ) {
          edges {
            node {
              id
              name
              listName: list_name
              content
            }
          }
        }
      }
    `}
    render={({ allTrelloCard }) => <App sections={allTrelloCard} />}
  />
);

export default Application;
