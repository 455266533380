import { ITheme, Link } from "@wel-shy/portfolio-component-library";
import styled from "styled-components";
import { Card } from "../Card";

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const Content = styled.div`
  align-self: flex-start;
  display: inline-block;
  min-height: 100px;
  padding: 1rem 0;
  width: 100%;
`;

export const GitHubCard = styled(Card)`
  display: flex;
  flex-direction: column;
`;

export const Stats = styled.div`
  align-self: flex-end;
  display: inline-block;
  align-items: center;
  width: 100%;
  div {
    display: flex;
    flex-direction: row;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg {
      rect {
        stroke: ${(props) => props.color};
      }
      polyline,
      #Rectangle-88 {
        fill: ${(props) => props.color};
        stroke: ${(props) => props.color};
      }
    }

    span {
      color: #000;
      margin-left: 0.5rem;
      display: inline-flex;
      align-items: center;
      font-family: ${({ theme }) => (theme as ITheme).font.sans};
    }
  }
`;

export const TitleWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5em;
  width: 100%;

  h6 {
    align-self: flex-start;
    display: inline-block;
    margin-bottom: 0;
    width: 100%;
  }
`;

export const A = styled(Link)`
  color: ${({ theme }) => (theme as ITheme).colors.info};
`;
