/**
 * Filter Trello lists by name.
 *
 * @param edges
 * @param listId
 * @returns
 */
export const FilterList = <T extends { listName: string; name: string }>(
  edges: { node: T }[],
  listId: string
) =>
  edges
    .map((edge) => edge.node)
    .filter(
      ({ listName, name }) => listName === listId && name.indexOf("type:") <= 0
    );
