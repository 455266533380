import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { initGA } from "../../utils/tracking";

interface TrelloNode {
  content: { content: string };
  name: string;
}

export const Analytics = () => {
  const {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(filter: { list_name: { regex: "/Analytics/" } }) {
        edges {
          node {
            content
            name
          }
        }
      }
    }
  `) as { allTrelloCard: { edges: { node: TrelloNode }[] } };

  const {
    node: {
      content: { content },
    },
  } = edges.find(({ node }) => node.name === "[google]");
  initGA(content);

  return <></>;
};
