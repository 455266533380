import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  Container,
  H2,
  H6,
  Paragraph,
} from "@wel-shy/portfolio-component-library";
import CodeIcon from "Static/icons/code.svg";
import BookMark from "Static/icons/bookmark.svg";

import SVG from "react-inlinesvg";
import { Wrapper, Content, Stats, GitHubCard, TitleWrapper, A } from "./styles";
import { Event } from "../../utils/tracking";
import { Grid, Item } from "../Grid";

interface GithubNode {
  id: string;
  name: string;
  url: string;
  description: string;
  stargazers: {
    totalCount: number;
  };
  forkCount: number;
  primaryLanguage: {
    name: string;
    color: string;
  };
}

export const RecentWork = () => {
  const {
    github: {
      user: { repositories, pinnedItems },
    },
  } = useStaticQuery(
    graphql`
      {
        github {
          user(login: "wel-shy") {
            pinnedItems(first: 6) {
              edges {
                node {
                  ... on GitHub_Repository {
                    id
                    name
                    url
                    description
                    stargazers {
                      totalCount
                    }
                    forkCount
                    primaryLanguage {
                      name
                      color
                    }
                  }
                }
              }
            }
            repositories(
              first: 3
              orderBy: { field: CREATED_AT, direction: DESC }
            ) {
              edges {
                node {
                  id
                  name
                  url
                  description
                  stargazers {
                    totalCount
                  }
                  forkCount
                  primaryLanguage {
                    name
                    color
                  }
                }
              }
            }
          }
        }
      }
    `
  ) as {
    github: {
      user: {
        repositories: { edges: { node: GithubNode }[] };
        pinnedItems: { edges: { node: GithubNode }[] };
      };
    };
  };

  const nodes = [
    ...pinnedItems.edges.map(({ node }) => {
      return { ...node, pinned: true };
    }),
    ...repositories.edges.map(({ node }) => node),
  ];

  const header = ({ name, pinned }: { name: string; pinned?: boolean }) => (
    <TitleWrapper>
      <H6>{name}</H6>
      {pinned && <SVG src={BookMark} />}
    </TitleWrapper>
  );

  return (
    <Wrapper as={Container} id="recent-work">
      <H2>Recent Work</H2>
      <Grid>
        {nodes.map((node) => (
          <Item
            key={node.id}
            as={A}
            href={node.url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => Event("Repositories", "click", node.name)}
          >
            <GitHubCard>
              <Content>
                {header(node)}
                <Paragraph>{node.description}</Paragraph>
              </Content>
              <Stats color={node.primaryLanguage.color}>
                <div>
                  <SVG src={CodeIcon} />
                  <span>{node.primaryLanguage.name}</span>
                </div>
              </Stats>
            </GitHubCard>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
