import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Wrapper } from "./styles";
import { FilterList } from "../../utils/trello";
import { TrelloGqlResult } from "../../models";
import { Container, H2 } from "@wel-shy/portfolio-component-library";
import { ContentData } from "./ContentData";

interface ContentProps {
  listName: string;
}

interface TrelloNode {
  id: string;
  name: string;
  listName: string;
  childMarkdownRemark: {
    html: string;
  };
}

export const Content = ({ listName }: ContentProps) => {
  const title = listName.replace(/\[section]/, "");

  const {
    allTrelloCard: { edges },
  } = useStaticQuery(graphql`
    {
      allTrelloCard(
        filter: { list_name: { regex: "/[section]/" } }
        sort: { fields: id, order: ASC }
      ) {
        edges {
          node {
            id
            name
            listName: list_name
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `) as TrelloGqlResult<TrelloNode>;

  if (!edges.length) {
    return null;
  }

  const items = FilterList<TrelloNode>(edges, listName).filter(
    ({ childMarkdownRemark }) => childMarkdownRemark
  );

  return (
    <Wrapper id={title.toLowerCase().replace(/\s/g, "-")}>
      <Container>
        <H2>{title}</H2>
        {items.map(({ childMarkdownRemark: { html } }) => (
          <ContentData html={html} listName={listName} />
        ))}
      </Container>
    </Wrapper>
  );
};
