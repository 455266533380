import React from "react";
import { parse, HTMLElement } from "node-html-parser";
import { HeaderLink, ContentParagraph } from "./styles";
import { H3, H4, H5 } from "@wel-shy/portfolio-component-library";

interface ContentDataProps {
  html: string;
  listName: string;
}

export const ContentData = ({ html, listName }: ContentDataProps) => {
  const doc = parse(html);
  const h3 = doc.querySelector("h3");
  const h4 = doc.querySelector("h4");
  const h5 = doc.querySelector("h5");
  const content = doc.querySelectorAll("p");

  const parseHeader = (doc: HTMLElement) => {
    const decode = (str: string) => str.replace("&#x26;", "/");
    const decodedInnerText = decode(doc.innerText);

    /*
     * TODO: Fix this hack, its a work around for inline hyperlinks.
     */
    if (listName === "Education[section]" || !doc.innerHTML.includes("</a>")) {
      return decodedInnerText;
    }

    const {
      attributes: { href },
      innerText,
    } = doc.querySelector("a");
    return <HeaderLink href={href}>{decode(innerText)}</HeaderLink>;
  };

  const contentHeader = <H3>{parseHeader(h3)}</H3>;

  return (
    <>
      {contentHeader}
      {h4 && <H4>{h4.innerText}</H4>}
      {h5 && <H5>{h5.innerText}</H5>}
      {content.map(({ innerHTML }, idx) => (
        <ContentParagraph
          key={idx}
          dangerouslySetInnerHTML={{
            __html: innerHTML || "",
          }}
        ></ContentParagraph>
      ))}
    </>
  );
};
