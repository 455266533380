import styled from "styled-components";
import { Tag } from "@wel-shy/portfolio-component-library";

export const SkillTag = styled(Tag)`
  display: inline-block;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const HeaderTagGroup = styled.div`
  flex: 1;
  text-align: right;
`;
